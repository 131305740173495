import { Environments, Constants } from '@seamless/core';
import { ILiveMarketEnvironment } from '@environments/environment.model';

export const environment: ILiveMarketEnvironment = {
  production: true,
  seamless: {
    logoutRedirect: 'https://autogate.co',
    logoutRedirectUrlByRouter: false,
    logoutRedirectWithTokenReset: 'https://autogate.co/lite/logout',
    api: {
      experience: 'https://livemarket-experience-api.trade.csnglobal.net/',
    },
    environment: Environments.Production,
    tenant: 'carsales',
    gATrackingScriptUrl:
      'https://livemarket-experience-api.trade.csnglobal.net/api/tracking/',
    cookieOptions: {
      domain: 'livemarket.autogate.co',
    },
    logoPath: 'https://autogate.co/lite/',
    product: Constants.ReferentialData.Products.LiveMarket,
  },
  country: Constants.Analysis.SpecCountries.AU,
  provider: Constants.ReferentialData.SpecProviders.Redbook,
  reportsApi:
    'https://livemarket-report-api-experience.prod.trade.csnglobal.net/',
  helpMeChooseUrl:
    'https://autogate.co/inventory-identification/help-me-choose?redirectApplicationId=80ab7982-8c77-4457-9254-4e8ecd7f6c1e&vertical=car&originatedFrom=valuations&searchMode=',
  seamlessPublic: {
    experienceApi: 'https://public-seamless-api.prod.trade.au.csnglobal.net/',
    tenant: 'au',
    version: 'v1',
    ownerId: '7479bb61-fd64-4912-9655-8548bbbf76d3',
  },
  appraisalSolutionsMoreInfo: {
    url: 'https://autogate.co/store/?product=appraisal-solutions',
  },
  livemarketInsightMoreInfo: {
    url: '/store/?product=livemarket-insights',
  },
  ppsrUrl: 'https://autogate.co/store/?product=regovin-and-ppsr-searches',
  liveMarketAdminUrl: 'https://admin.livemarket.autogate.co',
  sourcingLivemarketModeRedirect: {
    valuationsListUrl: 'https://autogate.co/sourcing/list?livemarket=true',
    addValuationUrl: 'https://autogate.co/inventory-identification/help-me-choose?redirectApplicationId=b58070a8-b15b-42b0-8974-ea2e0601d025&vertical=car&originatedFrom=list'
  },
  livemarketInventoryListUrl: 'https://autogate.co/livemarket',
  userManagementUrl: 'https://autogate.co/settings/users/profile/',
  liveMarketInventoryDetailsUrl: 'https://autogate.co/livemarket/details/'
};
